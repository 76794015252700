
import React from 'react';
  
function NotFound(props)
{
    return (
        <section id="LoadingScreen">
            <div className="loader"/>
        </section>
    );
};
  
export default NotFound;